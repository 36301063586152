import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      this.authService.isLoggedIn()
        .then((loggedIn) => {
          if (loggedIn) {
            resolve(false);
            this.router.navigate(['/dashboard']);
          } else {
            resolve(true);
          }
        });
    });
  }
}
