<div class="modal">
  <div class="modal-content">
    <div class="modal-header">
      <div>
        <ng-content select="[modal-header]"></ng-content>
      </div>
      <span class="close" (click)="closeModal($event)">&times;</span>
    </div>
    <div class="modal-body">
      <ng-content select="[modal-body]"></ng-content>
    </div>
    <div class="modal-footer">
      <ng-content select="[modal-footer]"></ng-content>
    </div>
  </div>
</div>
