import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      this.authService.isLoggedIn()
        .then((loggedin) => {
          if (loggedin) {
            resolve(true);
          } else {
            this.authService.doLogout().then(() => {
              this.router.navigate(['/login'])
            });
            resolve(false);
          }
        });
    });
  }

}
