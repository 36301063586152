import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-share-link-modal',
  templateUrl: './share-link-modal.component.html',
  styleUrls: ['./share-link-modal.component.scss']
})
export class ShareLinkModalComponent implements OnInit {

  @ViewChild('textarea') textarea: HTMLTextAreaElement | null = null;
  public linkUrl = '';
  constructor(private route: ActivatedRoute,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.linkUrl = params.link)
  }

  modalClose($event: any) { }

  shareLink(vendor: string) {
    const link = encodeURI(this.linkUrl);
    switch (vendor) {
      case 'fb':
        window.open(`https://www.facebook.com/sharer.php?u=${link}`);
        break;
      case 'wa':
        window.open(`https://api.whatsapp.com/send?text=${link}&url=${''}`);
        break;
      case 'tw':
        window.open(`https://twitter.com/share?url=${link}`);
        break;
      case 'ln':
        window.open(`http://www.linkedin.com/shareArticle?mini=true&url=${link}`);
        break;
      case 'sk':
        window.open(`https://web.skype.com/share?url=${link}`);
        break;
    }
  }

  copyLink() {

    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard();
      return;
    }
    navigator.clipboard
      .writeText(this.linkUrl)
      .then(() => {
        this.toast.success("Link copied to your clipboard", '');
        this.modalClose({ copied: true });
      })
      .catch(err => {
        this.toast.error("Failed to copy link", '');
        this.modalClose({ copied: false });
        console.log(err, "failed");
      });
  }

  fallbackCopyTextToClipboard() {
    if (this.textarea) {
      this.textarea.value = this.linkUrl;
      this.textarea.focus();
      this.textarea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.toast.success("Link copied to your clipboard", '');
          this.modalClose({ copied: true });
        }
      } catch (err) {
        this.toast.error("Failed to copy link", '');
        this.modalClose({ copied: false });
        console.log('err', err)
      }
    }
  }

}
