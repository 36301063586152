import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from '../../environments/environment';

export const TOKEN_NAME = 'BBTM_TOKEN';
export const UDATA = 'BBTM_USER';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly loginURL = `${environment.API_BASE_URL}login/reinst`;
  private readonly signupURL = `${environment.API_BASE_URL}user`;

  constructor(
    private http: HttpClient,
    private storage: StorageMap
  ) { }

  getToken(): Promise<any> {
    return this.storage.get(TOKEN_NAME).toPromise()
  }

  setToken(token: string) {
    this.storage.set(TOKEN_NAME, token).subscribe(() => { });
  }

  getDecodedToken(token: any) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  async getUData(): Promise<any> {
    return this.storage.get(UDATA).toPromise()
  }

  async setUData(user: any): Promise<any> {
    return this.storage.set(UDATA, user).toPromise();
  }

  getTokenExpirationDate(token: any): Date {
    const decoded = this.getDecodedToken(token);
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  async checkInvalidToken() {
    try {
      const token = await this.storage.get(TOKEN_NAME);
      const date = this.getTokenExpirationDate(token);
      if (!date) return;
      return !(date.valueOf() > new Date().valueOf());
    } catch (error) {
      return true;
    }
  }

  async isLoggedIn(): Promise<boolean> {
    // return true;
    try {
      const token = (await this.storage.get(TOKEN_NAME).toPromise());
      // if (token) {
      //   const expiry = this.getTokenExpirationDate(token);
      //   return expiry.getTime() >= new Date().getTime();
      // }
      return token ? true : false;
    } catch (error) {
      return false;
    }
  }

  doLogin(payload: any): Promise<any> {
    return this.doAction('login', payload);
  }

  doSignup(payload: any): Promise<any> {
    return this.doAction('signup', payload);
  }

  doAction(action: 'login' | 'signup', payload: any): Promise<any> {
    const url = action === 'login' ? this.loginURL : this.signupURL;
    return new Promise((resolve, reject) => {
      this.http.post(url, payload, { observe: 'response' })
        .subscribe(
          (response) => {
            const userData: any = response.body;
            this.setToken(userData.jwt);
            resolve({ ...userData });
          },
          (err) => reject(err)
        );
    });
  }

  doLogout() {
    return this.storage.clear().toPromise();
  }


}
