import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

export interface Alert {
    type: AlertType;
    message: string;
    title: string;
    autoClose: boolean;
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}

@Injectable()
export class ToastService {

    private subject = new Subject<Alert>();
    private readonly toastDuration = 4000;
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = true;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, title: string, autoClose = true, duration = this.toastDuration, keepAfterRouteChange = false) {
        this.alert(AlertType.Success, message, title, autoClose, duration, keepAfterRouteChange);
    }

    error(message: string, title: string, autoClose = true, duration = this.toastDuration, keepAfterRouteChange = false) {
        this.alert(AlertType.Error, message, title, autoClose, duration, keepAfterRouteChange);
    }

    info(message: string, title: string, autoClose = true, duration = this.toastDuration, keepAfterRouteChange = false) {
        this.alert(AlertType.Info, message, title, autoClose, duration, keepAfterRouteChange);
    }

    warn(message: string, title: string, autoClose = true, duration = this.toastDuration, keepAfterRouteChange = false) {
        this.alert(AlertType.Warning, message, title, autoClose, duration, keepAfterRouteChange);
    }

    alert(type: AlertType, message: string, title: string, autoClose = true, duration = this.toastDuration, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Alert>{ type, message, title, autoClose });
        if (autoClose) {
            setTimeout(() => {
                this.clear();
            }, duration);
        }
    }

    clear() {
        // clear alerts
        this.subject.next();
    }
}